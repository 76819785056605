import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Modal, Box, Typography } from '@mui/material';
// component
import ReactJson from "react-json-view";
import Iconify from '../../../components/Iconify';
// import Axios from "../../../api/AxiosInstance";
import useAxiosWithAuth from '../../../api/useAxiosWithAuth';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 650,
    overflow: "scroll",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UserMoreMenu({ id, showDelete = true, showEdit = true }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [rawData, setRawData] = useState([])
    const { get } = useAxiosWithAuth();


    useEffect(() => {
        const getData = async () => {
            try {
                const response = await get(`/order/${id}`)

                if (response.status === 200) {
                    setRawData(response?.data?.payload?.raw_data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (open) {
            getData()
        }
    }, [open])
    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem component={RouterLink} to={`/dashboard/orders/${id}`} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Iconify icon="akar-icons:arrow-forward-thick" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Details" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem onClick={handleOpen} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Iconify icon="bi:filetype-raw" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="View Raw Data" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4 }}>
                            Raw JSON Representation of Order Data
                        </Typography>
                        <ReactJson src={rawData} theme="shapeshifter:inverted" />
                    </Box>
                </Modal>
            </Menu>
        </>
    );
}
