import { Navigate } from 'react-router-dom';
import LoadingScreen from '../pages/LoadingScreen';

const AuthCheck = ({ isAuthenticated, children, navigateRoute, isLoading }) => {
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to={navigateRoute} />;
};

export default AuthCheck;
