import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const BillingShippingDetailsTab = ({billingDetails, shippingDetails}) => {
    return(
        <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
            <Grid item xs={12} md={6}>
                <h4>Billing Info</h4>
                <TableContainer component={Paper} sx={{maxHeight: 550}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Key</TableCell>
                                <TableCell align="left">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(billingDetails).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell width="30%">
                                        {Object.keys(billingDetails)[index]}
                                    </TableCell>
                                    <TableCell>
                                        {Object.values(billingDetails)[index]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
                <h4>Shipping Info</h4>
                <TableContainer component={Paper} sx={{maxHeight: 550}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Key</TableCell>
                                <TableCell align="left">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(shippingDetails).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell width="30%">
                                        {Object.keys(shippingDetails)[index]}
                                    </TableCell>
                                    <TableCell>
                                        {Object.values(shippingDetails)[index]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default BillingShippingDetailsTab;