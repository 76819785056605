/* eslint-disable camelcase  */
import {
    Card,
    Container,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useState} from "react";
import Scrollbar from "../components/Scrollbar";
import Page from "../components/Page";

import product_list from "../_mock/product_list";
import ProductsMoreMenu from "../sections/@dashboard/user/ProductsMoreMenu";

const ProductStockReport = () => {

    const {id} = useParams()
    return (<Page title="Product Stock">
        <Container>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
                <Typography variant="h4" gutterBottom>
                    {id} Stock Report
                </Typography>
            </Stack>

            <Card>
                <Scrollbar>
                    <TableContainer sx={{minWidth: 800, maxHeight: 550, p: 3}}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Product Code
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            Adaya 1:25
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Product Description
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            "1mg/mL THC and 25mg/mL CBD (30mL) (Schedule 8)",
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Product GUID
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            761a9095-8633-4359-8093-d2c5b59e9cb8
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Product Group Name
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            Cronos (Adaya)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Days Since Last Sale
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            0
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            On Purchase
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            0
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Allocated Quantity
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            3
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Available Quantity
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            710
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Quantity at Hand
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            713
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Average Cost
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            58
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Total Cost
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            41354
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Last Modified On
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {new Date(1653532713257).toDateString()} at {new Date(1653532713257).toLocaleTimeString()}
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableContainer>
                </Scrollbar>


            </Card>
        </Container>

    </Page>)
}

export default ProductStockReport;