/* eslint-disable camelcase */

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

const OthersTab = ({othersDetails}) => {
    const {tax_lines} = othersDetails

    return (
        <>
            <Typography sx={{my:5}} variant={"h5"}>Tax Lines</Typography>
            <TableContainer component={Paper} sx={{maxHeight: 550}}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Rate Code</TableCell>
                            <TableCell align="left">Rate ID</TableCell>
                            <TableCell align="left">Label</TableCell>
                            <TableCell align="left">Tax Total</TableCell>
                            <TableCell align="left">Shipping Tax Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tax_lines.map((tax_line, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {tax_line.id}
                                </TableCell>
                                <TableCell>
                                    {tax_line.rate_code}
                                </TableCell>
                                <TableCell>
                                    {tax_line.rate_id}
                                </TableCell>
                                <TableCell>
                                    {tax_line.label}
                                </TableCell>
                                <TableCell>
                                    {tax_line.tax_total}
                                </TableCell>
                                <TableCell>
                                    {tax_line.shipping_tax_total}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default OthersTab;