/* eslint-disable camelcase  */
/* eslint-disable no-plusplus */
import { AgGridReact } from 'ag-grid-react';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  TextField,
  OutlinedInput,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControlLabel,
  Modal,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Badge,
  CircularProgress,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserMoreMenu } from '../sections/@dashboard/user';
// mock
import useAxiosWithAuth from '../api/useAxiosWithAuth';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1250,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const colorAssign = (value) => {
  let color = '#00BCD4';
  if (value === 'completed') {
    color = '#388E3C';
  } else if (value === 'processing') {
    color = '#FF9800';
  } else if (value === 'on-hold') {
    color = '#EF5350';
  }
  return color;
};

export default function Orders() {
  const [page, setPage] = useState(0);
  const { get, post } = useAxiosWithAuth();

  const [showRemoveOption, setShowRemoveOption] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [asyncRunning, setAsyncRunning] = useState(true);

  const [products, setProducts] = useState([]);

  const [preset, setPreset] = useState([]);
  const [activePreset, setActivePreset] = useState({
    cart_hash: true,
    consignment_number: false,
    created_via: true,
    currency: true,
    customer: false,
    customer_ip_address: true,
    customer_user_agent: true,
    date: false,
    date_paid: true,
    order_id: false,
    payment_method: true,
    payment_method_title: true,
    preset_active: true,
    preset_name: 'Default Preset',
    products: false,
    shipping_tax: true,
    status: false,
    unStatus: false,
    total: true,
    total_discount: true,
    total_shipping: true,
    total_tax: true,
    transaction_id: true,
    unleashed_status: false,
    ware_s8_posted: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [paginationMeta, setPaginationMeta] = useState({ total: 2 });
  const [newPresetName, setNewPresetName] = useState('');
  const [tableData, setTableData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    setColumnDefs([
      { field: 'date', headerName: 'Date', resizable: true, hide: activePreset.date, width: 230 },
      { field: 'order_id', headerName: 'Order #', resizable: true, hide: activePreset.order_id, width: 100 },
      { field: 'customer', headerName: 'Customer', resizable: true, hide: activePreset.customer, width: 300 },
      {
        field: 'products',
        headerName: 'Products',
        resizable: true,
        hide: activePreset.products,
        width: 150,
        cellRendererFramework: (params) => (
          <Button size={'small'} onClick={() => handleProductModal(params.value)} variant="outlined">
            View Products
          </Button>
        ),
      },
      {
        field: 'status',
        headerName: 'WC Order Status',
        resizable: true,
        hide: activePreset.status,
        width: 150,
        cellRendererFramework: (params) => (
          <Typography variant="subtitle1" color={colorAssign(params.value)}>
            {params.value}
          </Typography>
        ),
      },
      { field: 'unStatus', headerName: 'UN Order Status', resizable: true, hide: activePreset.unStatus, width: 230 },
      {
        field: 'ware_s8_posted',
        headerName: 'WareS8 Posted',
        resizable: true,
        hide: activePreset.ware_s8_posted,
        width: 150,
      },
      {
        field: 'consignment_number',
        headerName: 'Consignment Number',
        resizable: true,
        hide: activePreset.consignment_number,
        editable: true,
        onCellValueChanged: (event) => {
          updateConsignmentNumber(event.data.actions, event.newValue, event.data.ware_s8_posted);
        },
      },
      { field: 'currency', headerName: 'Currency', resizable: true, hide: activePreset.currency },
      { field: 'total_discount', headerName: 'Total Discount', resizable: true, hide: activePreset.total_discount },
      { field: 'total_shipping', headerName: 'Total Shipping', resizable: true, hide: activePreset.total_shipping },
      { field: 'shipping_tax', headerName: 'Shipping Tax', resizable: true, hide: activePreset.shipping_tax },
      { field: 'total', headerName: 'Total', resizable: true, hide: activePreset.total },
      { field: 'total_tax', headerName: 'Total Tax', resizable: true, hide: activePreset.total_tax },
      { field: 'payment_method', headerName: 'Payment Method', resizable: true, hide: activePreset.payment_method },
      {
        field: 'payment_method_title',
        headerName: 'Payment Method Title',
        resizable: true,
        hide: activePreset.payment_method_title,
      },
      { field: 'transaction_id', headerName: 'Transaction ID', resizable: true, hide: activePreset.transaction_id },
      {
        field: 'customer_ip_address',
        headerName: 'Customer IP Address',
        resizable: true,
        hide: activePreset.customer_ip_address,
      },
      {
        field: 'customer_user_agent',
        headerName: 'Customer User Agent',
        resizable: true,
        hide: activePreset.customer_user_agent,
        width: 250,
      },
      { field: 'created_via', headerName: 'Created Via', resizable: true, hide: activePreset.created_via },
      { field: 'date_paid', headerName: 'Date Paid', resizable: true, hide: activePreset.date_paid },
      { field: 'cart_hash', headerName: 'Cart Hash', resizable: true, hide: activePreset.cart_hash },
      {
        field: 'actions',
        headerName: '',
        cellRendererFramework: (params) => <UserMoreMenu id={params.value} />,
        width: 50,
        pinned: 'right',
      },
    ]);
  }, [activePreset]);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();

  const { user } = useAuth0();

  const updateConsignmentNumber = async (id, newValue, ws8) => {
    try {
      const res = await post('product/update-order-meta', {
        order_id: id,
        consignment_number: newValue,
        ws_8_status: ws8 === 'No' ? '' : ws8,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getPreset = async () => {
      try {
        const response = await get(`get-config?email=${user?.email}`);
        const initPreset = response?.data?.payload?.preset;
        let newPreset = [];
        initPreset?.forEach((prst) => {
          let formattedPreset = {};
          formattedPreset = {};
          prst.forEach((item) => {
            formattedPreset[item?.Key] = item?.Value;
          });
          newPreset = [...newPreset, formattedPreset];
          if (formattedPreset?.preset_active) {
            setActivePreset(formattedPreset);
          }
        });
        setPreset(newPreset);
      } catch (err) {
        console.log(err);
      }
    };
    getPreset();
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await post(`/order-list?page_size=${rowsPerPage}&page_number=${page + 1}`, {
          'billing.company': filterValue,
          'shipping.company': filterValue,
          'line_items.name': filterValue,
          'line_items.meta_data.value': filterValue,
        });
        if (response.data.error === null) {
          setTableData(
            response?.data?.payload?.map((item) => ({
              date: `${new Date(item?.data?.date_created.slice(0, 19)).toLocaleDateString()} at ${new Date(
                item?.data?.date_created.slice(0, 19)
              ).toLocaleTimeString()}`,
              order_id: item?.data?.id,
              customer: item?.data?.billing?.company,
              products: item?.data?.line_items,
              status: item?.data?.status,
              unStatus: 'Placed',
              ware_s8_posted: item?.meta?.ws_8_status ? new Date(item?.meta?.posted_date).toLocaleDateString() : 'No',
              consignment_number: item?.meta?.consignment_number === '' ? 'N/A' : item?.meta?.consignment_number,
              currency: item?.data?.currency,
              total_discount: item?.data?.discount_total,
              total_shipping: item?.data?.shipping_total,
              shipping_tax: item?.data?.shipping_tax,
              total: item?.data?.total,
              total_tax: item?.data?.total_tax,
              payment_method: item?.data?.payment_method,
              payment_method_title: item?.data?.payment_method_title,
              transaction_id: item?.data?.transaction_id,
              customer_ip_address: item?.data?.customer_ip_address,
              customer_user_agent: item?.data?.customer_user_agent,
              created_via: item?.data?.created_via,
              date_paid:
                item?.data?.date_paid === undefined
                  ? 'Not Available'
                  : `${item?.data?.date_paid?.split('T')[0]} at ${item?.data?.date_paid?.split('T')[1]}`,
              cart_hash: item?.data?.cart_hash,
              actions: item?.data?._id,
            }))
          );
          setProducts(response?.data?.payload);
          setPaginationMeta(response?.data?.meta);
          setIsLoading(false);
          setAsyncRunning(false);
        }
      } catch (error) {
        setIsLoading(false);
        setTableData([]);
        setAsyncRunning(false);
      }
    };

    getData();
  }, [rowsPerPage, page, filterValue]);
  useEffect(() => {
    return setPage(0);
  }, []);

  const handleVisibility = (e, index) => {
    // eslint-disable-next-line
    let items = [...columnDefs];
    // eslint-disable-next-line
    let item = { ...items[index] };
    item.hide = !e.target.checked;
    items[index] = item;
    setColumnDefs(items);
  };

  const handleProductModal = (products) => {
    setSelectedItem(products);
    setOpen(true);
  };

  const handlePresetChange = async (item) => {
    item.preset_active = true;
    setActivePreset(item);
    const notActivePresets = preset.filter((singlePreset) => singlePreset.preset_name !== item.preset_name);
    for (let i = 0; i < notActivePresets.length; i++) {
      notActivePresets[i].preset_active = false;
    }
    const wholePresetObj = [...notActivePresets, item];
    const payload = {
      uid: user?.email,
      preset: wholePresetObj,
    };
    try {
      const res = await post(`update-config`, payload);
    } catch (err) {
      console.log(err);
    }
  };

  const savePreset = async () => {
    if (newPresetName.length > 0) {
      const currentPreset = {};
      for (let i = 0; i < columnDefs.length; i++) {
        currentPreset[columnDefs[i].field] = columnDefs[i].hide;
      }
      currentPreset.preset_active = false;
      currentPreset.preset_name = newPresetName;
      const payload = {
        uid: user?.email,
        preset: [...preset, currentPreset],
      };
      try {
        const res = await post(`update-config`, payload);
        toast.success('Preset added successfully');
        setPreset([...preset, currentPreset]);
        setDialogOpen(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.warn('Must Provide a Preset Name');
    }
  };

  const removePreset = async (i) => {
    if (preset[i].preset_active) {
      toast.warn('Cannot remove active preset');
    } else {
      const newPreset = preset.filter((item, index) => i !== index);
      const payload = {
        uid: user?.email,
        preset: newPreset,
      };
      try {
        const res = await post(`update-config`, payload);
        toast.success('Preset removed');
        setPreset(newPreset);
        setDialogOpen(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (user?.['http://healthhouse.com/user_type'] === 'supplier') {
      navigate('../supplier-report', { replace: true });
    }
  }, [asyncRunning]);

  const gridRef = useRef();

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <Page title="Orders">
      <Container>
        <Accordion style={{ marginBottom: '10px' }}>
          <AccordionSummary
            expandIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M21.886 5.536A1.002 1.002 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13a.998.998 0 0 0 1.644 0l9-13a.998.998 0 0 0 .064-1.033zM12 17.243L4.908 7h14.184L12 17.243z"
                />
              </svg>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant={'h5'}>Fields to Show</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {columnDefs.map(
                (item, index) =>
                  item.field !== 'actions' && (
                    <Grid item xs={6} md={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!item.hide}
                            onChange={(e) => {
                              handleVisibility(e, index);
                            }}
                          />
                        }
                        label={item.headerName}
                      />
                    </Grid>
                  )
              )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row">
              {preset?.map((item, index) => (
                <Badge
                  invisible={showRemoveOption}
                  badgeContent={<Iconify icon={'ep:remove-filled'} onClick={() => removePreset(index)} />}
                  color="error"
                  key={index}
                  sx={{ cursor: 'pointer' }}
                >
                  <Button
                    onClick={() => handlePresetChange(item)}
                    variant="outlined"
                    color={item?.preset_active ? 'success' : 'primary'}
                    sx={{ mx: 2 }}
                  >
                    {item?.preset_name}
                  </Button>
                </Badge>
              ))}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button variant="contained" color="primary" sx={{ height: 40 }} onClick={() => setDialogOpen(true)}>
                Save selected as preset
              </Button>
              <Button
                variant="contained"
                color="warning"
                sx={{ height: 40, ml: 1 }}
                onClick={() => setShowRemoveOption(!showRemoveOption)}
              >
                {showRemoveOption ? 'Remove a Preset' : 'Cancel'}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Save Preset</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Save your selected columns as a preset so that you can apply this same set of columns later quickly.
            </DialogContentText>
            <TextField
              autoFocus
              value={newPresetName}
              onChange={(e) => setNewPresetName(e.target.value)}
              margin="dense"
              id="preset_name"
              label="Preset Name"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={savePreset}>Save</Button>
          </DialogActions>
        </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
        </Stack>

        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
            <OutlinedInput
              size={'small'}
              sx={{ my: 4, ml: 2 }}
              id="outlined-adornment-weight"
              value={filterValue}
              placeholder="Search"
              onChange={(e) => setFilterValue(e.target.value)}
              endAdornment={
                <InputAdornment position="start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="m21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0a8.5 8.5 0 0 1 17 0Z"
                    />
                  </svg>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
            />
            <Stack direction="row">
              <Button
                sx={{ my: 4, mx: 2 }}
                variant="outlined"
                onClick={onBtnExport}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1.13em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128zM571 308l-95.7-96.4c-10.1-10.1-27.4-3-27.4 11.3V288h-64v64h64v65.2c0 14.3 17.3 21.4 27.4 11.3L571 332c6.6-6.6 6.6-17.4 0-24zm-379 28v-32c0-8.8 7.2-16 16-16h176V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V352H208c-8.8 0-16-7.2-16-16z"
                    />
                  </svg>
                }
              >
                Export
              </Button>
            </Stack>
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 450, p: 3 }}>
              <div className="ag-theme-material" style={{ height: 400, minWidth: 750 }}>
                {isLoading ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress size={72} sx={{ mb: 3 }} />
                    <h5>Please wait while we prepare your data</h5>
                  </div>
                ) : (
                  <AgGridReact rowData={tableData} columnDefs={columnDefs} ref={gridRef} />
                )}
              </div>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={paginationMeta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Product ID</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Subtotal</TableCell>
                <TableCell align="left">Subtotal Tax</TableCell>
                <TableCell align="left">Total</TableCell>
                <TableCell align="left">Total Tax</TableCell>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="left">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItem.map((lineItem, index) => (
                <TableRow key={index}>
                  <TableCell>{lineItem?.id}</TableCell>
                  <TableCell>{lineItem?.name}</TableCell>
                  <TableCell>{lineItem?.product_id}</TableCell>
                  <TableCell>{lineItem?.quantity}</TableCell>
                  <TableCell>{lineItem?.subtotal}</TableCell>
                  <TableCell>{lineItem?.subtotal_tax}</TableCell>
                  <TableCell>{lineItem?.total}</TableCell>
                  <TableCell>{lineItem?.total_tax}</TableCell>
                  <TableCell>{lineItem?.sku}</TableCell>
                  <TableCell>{lineItem?.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </Page>
  );
}
