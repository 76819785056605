/* eslint-disable camelcase  */

import { AgGridReact } from 'ag-grid-react';
import {
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

import Scrollbar from '../components/Scrollbar';
import useAxiosWithAuth from '../api/useAxiosWithAuth';

const ProductList = () => {
  const [columnDefs, setColumnDefs] = useState([
    {
      resizable: true,
      field: 'ProductCode',
      headerName: 'Product Code',
    },
    { resizable: true, field: 'ProductDesc', headerName: 'Product Description', width: 500 },
    { resizable: true, field: 'Width', headerName: 'Width' },
    { resizable: true, field: 'Height', headerName: 'Height' },
    { resizable: true, field: 'Depth', headerName: 'Depth' },
    { resizable: true, field: 'Weight', headerName: 'Weight' },
    { resizable: true, field: 'Barcode', headerName: 'Barcode' },
    { resizable: true, field: 'CreatedBy', headerName: 'Created By' },
    { resizable: true, field: 'CreatedOn', headerName: 'Created On' },
    {
      resizable: false,
      field: '',
      headerName: 'Action',
      pinned: 'right',
      width: 100,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Update Product Code">
            <IconButton aria-label="delete" onClick={() => handleClickOpenDialog(params.data)}>
              <Iconify icon={'tabler:edit'} width={22} height={22} />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ]);

  const { put, post } = useAxiosWithAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [asyncRunning, setAsyncRunning] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [currentProductCode, setCurrentProductCode] = useState('');

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = (data) => {
    setSelectedProductCode(data.ProductCode);
    setCurrentProductCode(data.ProductCode);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onUpdate = async () => {
    setIsLoading(true);
    try {
      const res = await put(`/product/update-product-code`, {
        old_code: currentProductCode,
        new_code: selectedProductCode,
      });
      if (res.status === 200) {
        toast.success('Product code updated successfully');
      }
    } catch (err) {
      console.log(err);
      toast.warn('Product code updated failed');
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setRefresh(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await post(`/unleashed/products?page_size=${rowsPerPage}&page_number=${page + 1}`, {});
        if (response.status === 200) {
          setProductList(
            response?.data?.payload.map((item) => ({
              ProductCode: item?.ProductCode === '' ? 'N/A' : item?.ProductCode,
              ProductDesc: item?.ProductDescription === '' ? 'N/A' : item?.ProductDescription,
              Width: item?.Width === '' ? 'N/A' : item?.Width,
              Height: item?.Height === '' ? 'N/A' : item?.Height,
              Depth: item?.Depth === '' ? 'N/A' : item?.Depth,
              Weight: item?.Weight === '' ? 'N/A' : item?.Weight,
              CreatedOn: `${new Date(parseInt(item?.CreatedOn.slice(6, 19), 10)).toDateString()} at ${new Date(
                parseInt(item?.CreatedOn.slice(6, 19), 10)
              ).toLocaleTimeString()}`,
              CreatedBy: item?.CreatedBy,
              Barcode: item?.Barcode === '' ? 'N/A' : item?.Barcode,
            }))
          );
          setPaginationMeta(response?.data?.meta);
          setTotal(response?.data?.meta?.total);
          setIsLoading(false);
          setAsyncRunning(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setAsyncRunning(false);
      } finally {
        setRefresh(false);
      }
    };

    getData();
  }, [rowsPerPage, page, refresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  return (
    <Page title="Product List">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
          <Typography variant="h4" gutterBottom>
            Product List
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 550, p: 3 }}>
              <div className="ag-theme-material" style={{ height: 500, minWidth: 750 }}>
                {isLoading ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress size={72} sx={{ mb: 3 }} />
                    <h5>Please wait while we prepare your data</h5>
                  </div>
                ) : (
                  <AgGridReact enableCellTextSelection="true" rowData={productList} columnDefs={columnDefs} />
                )}
              </div>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Update Product Code?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please be absolutely sure before changing the product code. This action might cause issues.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="productCode"
            label="New Product Code"
            type="text"
            fullWidth
            variant="standard"
            value={selectedProductCode}
            onChange={(e) => setSelectedProductCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={onUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ProductList;
