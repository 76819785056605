import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, TextField, Select, InputLabel, FormControl, MenuItem } from '@mui/material';

import { LoadingButton } from '@mui/lab';

// hooks
// components
import Page from '../components/Page';
import useAxiosWithAuth from '../api/useAxiosWithAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function Register({ user = {}, setShouldRefresh, isFromModal = false }) {
  const [userData, setUserData] = useState({ name: '', email: '', role: '', connection: 'email', supplierCode: '' });
  const [error, setError] = useState({ name: '', email: '', role: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [supplierList, setSupplierList] = useState([]);
  const { get, post } = useAxiosWithAuth();

  useEffect(() => {
    if ('name' in user) {
      setUserData({
        name: user?.name,
        email: user?.email,
        role: user?.type,
        supplierCode: user?.supplier_code,
      });
    }
  }, [user]);

  const handleSubmit = async () => {
    if (isFromModal) {
      setShouldRefresh(true);
    }
    if (userData.name.length < 1) {
      setError({ name: 'Name is required', email: '', role: '' });
    } else if (userData.role < 1) {
      setError({ name: '', email: '', role: 'Role is required' });
    } else if (userData.email.length < 1) {
      setError({ name: '', email: 'Email is required', role: '' });
    } else {
      setError({ name: '', email: '', role: '' });
      let response;
      if ('name' in user) {
        response = await post(`/update-user/${user?.id}`, {
          name: userData.name,
          email: userData.email,
          connection: userData.connection,
          user_metadata: {
            user_type: userData.role,
            supplier_code: userData.supplierCode,
          },
        });
      } else {
        response = await post('/create-user', {
          name: userData.name,
          email: userData.email,
          connection: userData.connection,
          user_metadata: {
            user_type: userData.role,
            supplier_code: userData.supplierCode,
          },
        });
      }

      if (response?.data?.message === 'user has already exist') {
        toast.warn('This user already exists');
      } else if (response?.data?.message === 'User created successfully') {
        toast.success(response?.data?.message);
      } else if (response?.data?.payload === 'update user successfully') {
        toast.success(response?.data?.message);
      }
      setUserData({ name: '', role: '', email: '', connection: 'email' });
      setError({ name: '', role: '', email: '' });
    }
    if (isFromModal) {
      setShouldRefresh(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await get(`/unleashed/product-groups`, {});
        if (response.status === 200) {
          //   setSupplierList(
          //     response?.data?.map((item) => ({
          //       SupplierCode: item?.SupplierCode === '' ? 'N/A' : item?.SupplierCode,
          //       SupplierName: item?.SupplierName === '' ? 'N/A' : item?.SupplierName,
          //       Email: item?.Email === '' ? 'N/A' : item?.Email,
          //       PhoneNumber: `${item?.phone_number === '' ? 'N/A' : item?.phone_number} / ${
          //         item?.MobileNumber === '' ? 'N/A' : item?.MobileNumber
          //       }`,
          //       CreatedOn: `${new Date(parseInt(item?.CreatedOn.slice(6, 19), 10)).toDateString()} at ${new Date(
          //         parseInt(item?.CreatedOn.slice(6, 19), 10)
          //       ).toLocaleTimeString()}`,
          //       CreatedBy: item?.CreatedBy,
          //       Website: item?.Website === '' ? 'N/A' : item?.Website,
          //         value: item?.GroupName,
          //         label: item?.GroupName,
          //     }))
          //   );
          setSupplierList(response?.data);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <Page title="Register">
      <RootStyle>
        <Container>
          <Typography variant="h4" gutterBottom>
            Add a new user!
          </Typography>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Name"
                value={userData.name}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                error={error.name.length > 0}
                helperText={error.name}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Role"
                  value={userData.role}
                  onChange={(e) => setUserData({ ...userData, role: e.target.value })}
                  error={error.role.length > 0}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="supplier">Supplier</MenuItem>
                </Select>
              </FormControl>
              {userData.role === 'supplier' && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Product Group</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product Group"
                      value={userData.supplierCode}
                      onChange={(e) => setUserData({ ...userData, supplierCode: e.target.value })}
                    >
                      {supplierList?.map((supplier) => (
                        <MenuItem key={supplier?.GroupName} value={supplier?.GroupName}>
                          {supplier?.GroupName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <FormControl fullWidth>
                    <ReactSelect
                      id="demo-simple-select"
                      label="Supplier Code"
                      value={
                        typeof userData.supplierCode === 'string'
                          ? [{ value: userData.supplierCode, label: userData.supplierCode }]
                          : userData.supplierCode
                      } 
                      options={supplierList}
                      onChange={(e) => setUserData({ ...userData, supplierCode: e })}
                      isMulti
                    />
                  </FormControl> */}
                </>
              )}
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              error={error.email.length > 0}
              helperText={error.email}
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              // error={Boolean(touched.email && errors.email)}
              // helperText={touched.email && errors.email}
            />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
              {'name' in user ? 'Edit User' : 'Add User'}
            </LoadingButton>
          </Stack>
        </Container>
      </RootStyle>
    </Page>
  );
}
