/* eslint-disable camelcase  */

import {
  Alert,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  Button,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import useAxiosWithAuth from '../api/useAxiosWithAuth';
import Iconify from '../components/Iconify';

function convertToDate(dotNetDate) {
  // Extract the number from the string
  const timestamp = parseInt(dotNetDate.replace(/\/Date\((-?\d+)\)\//, '$1'), 10);

  // Create a new Date object using the timestamp
  const date = new Date(timestamp);

  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return date.toLocaleString('en-GB', options);
}

const SupplierReportRedesigned = () => {
  const [columnDefs, setColumnDefs] = useState([
    { resizable: true, field: 'ProductCode', headerName: 'Product Code', type: 'centerAligned', width: 200 },
    { resizable: true, field: 'ProductDescription', headerName: 'Product Description', width: 350 },
    // {
    //   resizable: true,
    //   field: 'AvailableQty',
    //   headerName: 'Available Quantity',
    //   width: 200,
    //   cellStyle: () => ({
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   }),
    // },
    {
      resizable: true,
      field: 'QtyOnHand',
      headerName: 'Quantity at Hand',
      width: 200,
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
    },
    {
      resizable: true,
      field: 'Sales',
      headerName: 'Completed Sales',
      // cellRenderer: (params) => params.data.QtyOnHand - params.data.AvailableQty,
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      width: 200,
    },
    {
      resizable: false,
      field: '',
      headerName: 'Action',
      pinned: 'right',
      width: 100,
      cellRenderer: (params) => (
        <span style={{ padding: 2 }}>
          <Tooltip title="Update Product Code">
            <IconButton aria-label="delete" onClick={() => handleClickOpenDialog(params.data)}>
              <Iconify icon={'tabler:edit'} width={22} height={22} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Batch Details">
            <IconButton aria-label="delete" onClick={() => handleClickOpenBatchDialog(params.data)}>
              <Iconify icon={'grommet-icons:view'} width={22} height={22} />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ]);

  const { post, put, get } = useAxiosWithAuth();
  const [stockReport, setStockReport] = useState([]);

  const maxDate = new Date().toISOString().split('T')[0];

  const date = new Date().toISOString().slice(0, 7);
  // const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1, 12);
  // const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12);

  // const formattedFirstDate = firstDayOfCurrentMonth.toISOString().slice(0, 10);
  // const formattedLastDate = lastDayOfCurrentMonth.toISOString().slice(0, 10);

  const [reportDate, setReportDate] = useState(date);
  const [reportEndDate, setReportEndDate] = useState(date);
  const [isLoading, setIsLoading] = useState(true);
  const [isBatchLoading, setIsBatchLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [currentProductCode, setCurrentProductCode] = useState('');

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openBatchDialog, setOpenBatchDialog] = React.useState(false);

  const [batchInfo, setBatchInfo] = React.useState([]);

  const handleClickOpenDialog = (data) => {
    setSelectedProductCode(data.ProductCode);
    setCurrentProductCode(data.ProductCode);
    setOpenDialog(true);
  };

  const handleClickOpenBatchDialog = (data) => {
    setSelectedProductCode(data.ProductCode);
    setCurrentProductCode(data.ProductCode);
    setOpenBatchDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBatchCloseDialog = () => {
    setOpenBatchDialog(false);
  };

  const onUpdate = async () => {
    setIsLoading(true);
    try {
      const res = await put(`/product/update-product-code`, {
        old_code: currentProductCode,
        new_code: selectedProductCode,
      });
      if (res.status === 200) {
        toast.success('Product code updated successfully');
      }
    } catch (err) {
      console.log(err);
      toast.warn('Product code updated failed');
    } finally {
      setIsLoading(false);
      setOpenDialog(false);
      setShouldRefresh(true);
    }
  };

  const { user } = useAuth0();

  useEffect(() => {
    const getReport = async () => {
      setIsLoading(true);
      const date = new Date(reportDate);
      const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1, 12);
      const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12);

      const formattedFirstDate = firstDayOfCurrentMonth.toISOString().slice(0, 10);
      const formattedLastDate = lastDayOfCurrentMonth.toISOString().slice(0, 10);
      const payload = {
        start_date: formattedFirstDate,
        end_date: formattedLastDate,
      };
      try {
        const res = await post(
          `product/stock-report-for-supplier/${
            user['http://healthhouse.com/supplier_code']
          }?page_size=${rowsPerPage}&page_number=${page + 1}`,
          payload
        );
        setStockReport(res.data.payload);
        setTotal(res.data.meta.total_size);
        setStockReport(res.data.payload);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      } finally {
        setShouldRefresh(false);
      }
    };

    getReport();
  }, [reportDate, reportEndDate, page, rowsPerPage, user, shouldRefresh]);

  useEffect(() => {
    const batchInfo = async () => {
      setIsBatchLoading(true);
      try {
        const res = await get(`batch/batches-by-sku/${selectedProductCode}`);
        setBatchInfo(res.data.payload);
        setIsBatchLoading(false);
      } catch (err) {
        console.log(err);
        setIsBatchLoading(false);
      } finally {
        setIsBatchLoading(false);
      }
    };

    batchInfo();
  }, [user, selectedProductCode]);

  const paginationNumberFormatter = useCallback((params) => {
    return `[${params.value.toLocaleString()}]`;
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page title="Stock Report">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
          <Typography variant="h4" gutterBottom>
            Supplier's Report
          </Typography>
          <Stack direction={'row'} gap={2}>
            {/* <TextField
              label="Start Date"
              type="month"
              value={reportDate.slice(0, 7)}
              variant="outlined"
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const firstDayOfSelectedMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
                const formattedDate = firstDayOfSelectedMonth.toISOString().slice(0, 10);
                setReportDate(formattedDate);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled={reportDate === new Date().toISOString().slice(0, 10)}
              label="End Date"
              type="month"
              value={reportEndDate.slice(0, 7)}
              variant="outlined"
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const lastDayOfSelectedMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
                const formattedDate = lastDayOfSelectedMonth.toISOString().slice(0, 10);
                setReportEndDate(formattedDate);
              }}
              inputProps={{
                max: maxDate,
                min: reportDate,
              }}
            /> */}
            <TextField
              label="Select Month"
              type="month"
              value={reportDate}
              variant="outlined"
              onChange={(e) => {
                setReportDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
        <Alert sx={{ my: 2 }} severity="info">
          Selected month will only affect the <strong>Units sold</strong> column.
        </Alert>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 550, p: 3 }}>
              <div className="ag-theme-material" style={{ height: 500, minWidth: 750 }}>
                {isLoading ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress size={72} sx={{ mb: 3 }} />
                    <h5>Please wait while we prepare your data</h5>
                  </div>
                ) : (
                  <AgGridReact rowData={stockReport} columnDefs={columnDefs} />
                )}
              </div>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {/* <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, p: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Product</TableCell>
                    <TableCell align="center">Product Description</TableCell>
                    <TableCell align="center">Qty on Hand</TableCell>
                    <TableCell align="center">Available Quantity</TableCell>
                    <TableCell align="center">Units Sold</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockReport?.map((item, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">{item?.ProductCode}</TableCell>
                      <TableCell align="center">{item?.ProductDescription}</TableCell>
                      <TableCell align="center">{item?.QtyOnHand}</TableCell>
                      <TableCell align="center">{item?.AvailableQty}</TableCell>
                      <TableCell align="center">{item?.QtyOnHand - item?.AvailableQty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card> */}
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Update Product Code?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please be absolutely sure before changing the product code. This action might cause issues.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="productCode"
            label="New Product Code"
            type="text"
            fullWidth
            variant="standard"
            value={selectedProductCode}
            onChange={(e) => setSelectedProductCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={onUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
      {/* batch dialog */}
      <Dialog maxWidth={'xl'} open={openBatchDialog} onClose={handleBatchCloseDialog}>
        <DialogTitle>Batch Details</DialogTitle>
        <DialogContent>
          {isBatchLoading && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress size={72} sx={{ mb: 3 }} />
              <h5>Please wait while we prepare your data</h5>
            </div>
          )}
          {!isBatchLoading && (
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="left">GUID</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Created On</TableCell>
                    <TableCell align="center">Last Modified By</TableCell>
                    <TableCell align="center">Last Modified On</TableCell> */}
                    <TableCell align="left">Batch</TableCell>
                    <TableCell align="center">Expiry Date</TableCell>
                    {/* <TableCell align="center">Original Qty</TableCell> */}
                    <TableCell align="center">Quantity</TableCell>
                    {/* <TableCell align="center">Status</TableCell> */}
                    <TableCell align="right">Warehouse Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchInfo?.map((item, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {/* <TableCell align="left">{item?.Guid}</TableCell>
                      <TableCell align="center">{item?.CreatedBy}</TableCell>
                      <TableCell align="center">{convertToDate(item?.CreatedOn)}</TableCell>
                      <TableCell align="center">{item?.LastModifiedBy}</TableCell>
                      <TableCell align="center">{convertToDate(item?.LastModifiedOn)}</TableCell> */}
                      <TableCell align="left">{item?.Number}</TableCell>
                      <TableCell align="center">{convertToDate(item?.ExpiryDate)}</TableCell>
                      {/* <TableCell align="center">{item?.OriginalQty}</TableCell> */}
                      <TableCell align="center">{item?.Quantity}</TableCell>
                      {/* <TableCell align="center">{item?.Status}</TableCell> */}
                      <TableCell align="right">{item?.WarehouseCode}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBatchCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default SupplierReportRedesigned;
