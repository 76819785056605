/* eslint-disable camelcase  */
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const PrimaryDetailsTab = ({primaryDetails}) => {
    let newPrimaryDetails = {}
    if("raw_data" in primaryDetails){
        const {raw_data, ...restPrimaryDetails} = primaryDetails;
        newPrimaryDetails = restPrimaryDetails
    } else{
        newPrimaryDetails = primaryDetails
    }

    return(
        <TableContainer component={Paper} sx={{maxHeight: 550}}>
            <Table size="small" sx={{minWidth: 650}} aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell width="30%">
                            DB ID
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails._id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Order ID
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            WC Order Status
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.status}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Currency
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.currency}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Version
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.version}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Date Created
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails?.date_created?.split("T")[0]} at {newPrimaryDetails?.date_created?.split("T")[1]?.slice(0, 8)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell width="30%">
                        Date Modified
                    </TableCell>
                    <TableCell>
                        {newPrimaryDetails?.date_modified?.split("T")[0]} at {newPrimaryDetails?.date_modified?.split("T")[1]}
                    </TableCell>
                </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Total Discount
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.discount_total}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Discount Tax
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.discount_tax}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Total Shipping
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.shipping_total}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Shipping Tax
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.shipping_tax}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Cart Tax
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.cart_tax}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Total
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.total_tax}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Total Tax
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.total_tax}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Customer ID
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.customer_id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Order Key
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.order_key}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Payment Method
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.payment_method}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Payment Method Title
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.payment_method_title}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Transaction ID
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.transaction_id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Customer IP Address
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.customer_ip_address}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Customer User Agent
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.customer_user_agent}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Created Via
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.created_via}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Date Paid
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails?.date_paid?.split("T")[0]}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Cart Hash
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.cart_hash}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Number
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.number}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Date Created GMT
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.date_created_gmt?.split("T")[0]}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Date Modified GMT
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.date_modified_gmt?.split("T")[0]}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Date Paid GMT
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.date_paid_gmt?.split("T")[0]}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Currency Symbol
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails.currency_symbol}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="30%">
                            Product WareS8 ID
                        </TableCell>
                        <TableCell>
                            {newPrimaryDetails._product_wares8_id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            {primaryDetails?.status}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Total
                        </TableCell>
                        <TableCell>
                            {primaryDetails?.total}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Total Tax
                        </TableCell>
                        <TableCell>
                            {primaryDetails?.total_tax}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PrimaryDetailsTab;