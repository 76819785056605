import ReactJson from "react-json-view";
import { useEffect, useState } from "react";
import axios from "axios";
import {
    Avatar,
    Box, Grid,
    Paper, Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";

const CustomerDetailsTab = ({ customerId }) => {
    const [rawData, setRawData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            try {
                const res = await axios.get(`https://shop.healthhouse.com.au/wp-json/wc/v3/customers/${customerId}`, {
                    headers: {
                        "Authorization": "Basic Y2tfYWZhNzljNTgwNjliYjk1M2ZkMjViMTg5ZmVjN2MwM2M0OTJhMjdiMDpjc19hYmIwNjQ4YjZlM2RhNGY1MjQ5ZWU0OWNhMzQ2Mjg2Y2Y3YjI5YzY5"
                    }
                })
                setRawData(res.data)
                setIsLoading(false)
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
        getData()
    }, [])
    return (
        <Box>
            <Paper elevation={3} sx={{ p: 3 }}>
                {isLoading ?
                    <Box>
                        <Stack alignItems="center">
                            <Skeleton variant="rectangular" width={96} height={96} sx={{ my: 2 }} />
                        </Stack>
                        <Skeleton variant="rectangular" sx={{ my: 2 }} />
                        <Skeleton variant="rectangular" sx={{ my: 2 }} />
                        <Skeleton variant="rectangular" sx={{ my: 2 }} />
                        <Skeleton variant="rectangular" sx={{ my: 2 }} />
                    </Box>
                    : <>
                        <Stack alignItems="center">
                            <Avatar sx={{ width: 96, height: 96, my: 2 }} src={rawData?.avatar_url} variant="rounded" />
                            <Typography variant="h5">{`${rawData?.first_name} ${rawData?.last_name}`} <span
                                style={{ color: "#1565C0" }}>({rawData?.id})</span></Typography>
                            <Typography variant="h6">Ware S8 ID: <span
                                style={{ color: "#1565C0" }}>{rawData?.meta_data?.filter(item => item?.key === "wpuef_cid_c21")[0]?.value}</span></Typography>
                        </Stack>
                        <Stack alignItems={"center"} sx={{ my: 2 }}>
                            <TableContainer component={Paper} sx={{ maxWidth: 500, border: "1px solid rgba(0,0,0,0.1)" }}>
                                <Table size="small" aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">First Name</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1">{rawData?.first_name}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">Last Name</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1">{rawData?.last_name}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">Email</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1">{rawData?.email}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">Username</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1">{rawData?.username}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">Role</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1">{rawData?.role}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">
                                                <Typography variant="subtitle1">Is Paying Customer</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1">{rawData?.is_paying_customer ? "true" : "false"}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </>
                }
            </Paper>
            <Grid container sx={{ my: 2 }} spacing={2}>
                <Grid item md={6} xs={12}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        {isLoading ?
                            <Box>
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                            </Box>
                            :
                            <>
                                <Typography variant="h6">Billing Details</Typography>
                                <TableContainer component={Paper} sx={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Table size="small" aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">First Name</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.first_name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Last Name</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.last_name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Email</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.email}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Phone</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.phone}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Company</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.company}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Address 1</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.address_1}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Address 2</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.address_2}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Postcode</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.postcode}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">City</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.city}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">State</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.state}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Country</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.billing?.country}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        {isLoading ?
                            <Box>
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                                <Skeleton variant="rectangular" sx={{ my: 2 }} />
                            </Box>
                            :
                            <>
                                <Typography variant="h6">Shipping Details</Typography>
                                <TableContainer component={Paper} sx={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Table size="small" aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">First Name</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.first_name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Last Name</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.last_name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Phone</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.phone}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Company</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.company}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Address 1</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.address_1}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Address 2</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.address_2}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Postcode</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.postcode}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">City</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.city}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">State</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.state}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <Typography variant="subtitle1">Country</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1">{rawData?.shipping?.country}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
export default CustomerDetailsTab;