/* eslint-disable camelcase */

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
// import Axios from "../api/AxiosInstance";
import useAxiosWithAuth from '../api/useAxiosWithAuth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 10,
  borderRadius: '8px',
  p: 4,
};

const LineItemsTab = ({ lineItemsDetails, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [patientInfo, setPatientInfo] = useState({
    id: 0,
    patientType: '',
    tgaRef: '',
    patientInitial: '',
    patientDOB: '',
    prescriberFirstname: '',
    prescriberLastname: '',
  });
  const [index, setIndex] = useState(null);
  const [batchEditDialog, setBatchEditDialog] = useState(false);
  const [expEditDialog, setExpEditDialog] = useState(false);
  const [batchNumber, setBatchNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const { put } = useAxiosWithAuth();

  const handleSubmit = async () => {
    const lineItems = [...lineItemsDetails];
    // lineItems[index]?.meta_data?.filter(item => item?.key === "please_select_patient_type")[0]?.value
    const typeIndex = lineItems[index].meta_data.findIndex((meta) => meta?.key === 'please_select_patient_type');
    const tgaIndex = lineItems[index].meta_data.findIndex((meta) => meta?.key === 'tga_approval_reference_number');
    const initialIndex = lineItems[index].meta_data.findIndex((meta) => meta?.key === 'patient_initials');
    const dobIndex = lineItems[index].meta_data.findIndex((meta) => meta?.key === 'patient_date_of_birth');
    const prescriberFirstnameIndex = lineItems[index].meta_data.findIndex(
      (meta) => meta?.key === 'prescriber_first_name'
    );
    const prescriberLastnameIndex = lineItems[index].meta_data.findIndex(
      (meta) => meta?.key === 'prescriber_last_name'
    );

    if (typeIndex !== -1) {
      lineItems[index].meta_data[typeIndex].value = patientInfo?.patientType;
      lineItems[index].meta_data[typeIndex].display_value = patientInfo?.patientType;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'please_select_patient_type',
        value: patientInfo?.patientType,
        display_key: 'please_select_patient_type',
        display_value: patientInfo?.patientType,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    if (tgaIndex !== -1) {
      lineItems[index].meta_data[tgaIndex].value = patientInfo?.tgaRef;
      lineItems[index].meta_data[tgaIndex].display_value = patientInfo?.tgaRef;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'tga_approval_reference_number',
        value: patientInfo?.tgaRef,
        display_key: 'tga_approval_reference_number',
        display_value: patientInfo?.tgaRef,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    if (dobIndex !== -1) {
      lineItems[index].meta_data[dobIndex].value = patientInfo?.patientDOB;
      lineItems[index].meta_data[dobIndex].display_value = patientInfo?.patientDOB;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'patient_date_of_birth',
        value: patientInfo?.patientDOB,
        display_key: 'patient_date_of_birth',
        display_value: patientInfo?.patientDOB,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    if (initialIndex !== -1) {
      lineItems[index].meta_data[initialIndex].value = patientInfo?.patientInitial;
      lineItems[index].meta_data[initialIndex].display_value = patientInfo?.patientInitial;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'patient_initials',
        value: patientInfo?.patientInitial,
        display_key: 'patient_initials',
        display_value: patientInfo?.patientInitial,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    if (prescriberFirstnameIndex !== -1) {
      lineItems[index].meta_data[prescriberFirstnameIndex].value = patientInfo?.prescriberFirstname;
      lineItems[index].meta_data[prescriberFirstnameIndex].display_value = patientInfo?.prescriberFirstname;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'prescriber_first_name',
        value: patientInfo?.prescriberFirstname,
        display_key: 'prescriber_first_name',
        display_value: patientInfo?.prescriberFirstname,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    if (prescriberLastnameIndex !== -1) {
      lineItems[index].meta_data[prescriberLastnameIndex].value = patientInfo?.prescriberLastname;
      lineItems[index].meta_data[prescriberLastnameIndex].display_value = patientInfo?.prescriberLastname;
    } else {
      const patientTypeObj = {
        id: lineItems[index].meta_data[lineItems[index].meta_data.length - 1].id + 1,
        key: 'prescriber_last_name',
        value: patientInfo?.prescriberLastname,
        display_key: 'prescriber_last_name',
        display_value: patientInfo?.prescriberLastname,
      };
      lineItems[index].meta_data = [...lineItems[index].meta_data, patientTypeObj];
    }

    try {
      const res = await put(`order/${id}`, { line_items: lineItems });
      if (res?.status === 200) {
        toast.success('Data updated successfully.');
      }
      setIsModalOpen(false);
      setIsEdit(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
      setIsModalOpen(false);
      setIsEdit(false);
    }
  };

  const updateBatchNumber = async () => {
    const lineItems = [...lineItemsDetails];
    lineItems[index].batch_number = batchNumber;
    setBatchNumber('');
    setBatchEditDialog(false);
    try {
      const res = await put(`order/${id}`, { line_items: lineItems });
      if (res?.status === 200) {
        toast.success('Data updated successfully.');
      }
      setIsModalOpen(false);
      setIsEdit(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
      setIsModalOpen(false);
      setIsEdit(false);
    }
  };

  const updateExpiryDate = async () => {
    const lineItems = [...lineItemsDetails];
    lineItems[index].expiry_date = expiryDate;
    setExpiryDate('');
    setExpEditDialog(false);
    try {
      const res = await put(`order/${id}`, { line_items: lineItems });
      if (res?.status === 200) {
        toast.success('Data updated successfully.');
      }
      setIsModalOpen(false);
      setIsEdit(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
      setIsModalOpen(false);
      setIsEdit(false);
    }
  };

  const handleClick = (i) => {
    setIndex(i);
    setIsModalOpen(true);
    setPatientInfo({
      // id: lineItemsDetails[i]?.meta_data?.at(-1)?.value[1]?.Value[3]?.Value === null ? "N/A" : lineItemsDetails[i]?.meta_data?.at(-1)?.value[1]?.Value[3]?.Value,
      patientType: lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'please_select_patient_type')[0]
        ?.value,
      tgaRef: lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'tga_approval_reference_number')[0]?.value,
      patientInitial: lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'patient_initials')[0]?.value,
      patientDOB: lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'patient_date_of_birth')[0]?.value,
      prescriberFirstname:
        lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'prescriber_first_name')[0]?.value === undefined
          ? ''
          : lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'prescriber_first_name')[0]?.value,
      prescriberLastname:
        lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'prescriber_last_name')[0]?.value === undefined
          ? ''
          : lineItemsDetails[i]?.meta_data?.filter((item) => item?.key === 'prescriber_last_name')[0]?.value,
    });
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
      <Table sx={{ minWidth: 650, whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left" width={350}>
              Name
            </TableCell>
            <TableCell align="left" width={150}>
              Product ID
            </TableCell>
            <TableCell align="left" width={150}>
              Quantity
            </TableCell>
            <TableCell align="left" width={150}>
              Subtotal
            </TableCell>
            <TableCell align="left" width={150}>
              Subtotal Tax
            </TableCell>
            <TableCell align="left" width={150}>
              Total
            </TableCell>
            <TableCell align="left" width={150}>
              Total Tax
            </TableCell>
            <TableCell align="left" width={150}>
              SKU
            </TableCell>
            <TableCell align="left" width={150}>
              Price
            </TableCell>
            <TableCell align="left" width={150}>
              Batch Number
            </TableCell>
            <TableCell align="left" width={150}>
              Expiry Date
            </TableCell>
            <TableCell align="right" width={150}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItemsDetails.map((lineItem, index) => (
            <TableRow key={index}>
              <TableCell>{lineItem?.id}</TableCell>
              <TableCell>{lineItem?.name}</TableCell>
              <TableCell>{lineItem?.product_id}</TableCell>
              <TableCell>{lineItem?.quantity}</TableCell>
              <TableCell>{lineItem?.subtotal}</TableCell>
              <TableCell>{lineItem?.subtotal_tax}</TableCell>
              <TableCell>{lineItem?.total}</TableCell>
              <TableCell>{lineItem?.total_tax}</TableCell>
              <TableCell>{lineItem?.sku}</TableCell>
              <TableCell>{lineItem?.price}</TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center">
                  <Typography>{lineItem?.batch_number}</Typography>
                  <IconButton
                    onClick={() => {
                      setBatchEditDialog(true);
                      setIndex(index);
                      setBatchNumber(lineItem?.batch_number);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M4.42 20.579a1 1 0 0 1-.737-.326a.988.988 0 0 1-.263-.764l.245-2.694L14.983 5.481l3.537 3.536L7.205 20.33l-2.694.245a.95.95 0 0 1-.091.004ZM19.226 8.31L15.69 4.774l2.121-2.121a1 1 0 0 1 1.415 0l2.121 2.121a1 1 0 0 1 0 1.415l-2.12 2.12l-.001.001Z"
                      />
                    </svg>
                  </IconButton>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center">
                  <Typography>{new Date(lineItem?.expiry_date).toDateString()}</Typography>
                  <IconButton
                    onClick={() => {
                      setExpEditDialog(true);
                      setIndex(index);
                      setExpiryDate(lineItem?.expiry_date?.split('T')[0]);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M4.42 20.579a1 1 0 0 1-.737-.326a.988.988 0 0 1-.263-.764l.245-2.694L14.983 5.481l3.537 3.536L7.205 20.33l-2.694.245a.95.95 0 0 1-.091.004ZM19.226 8.31L15.69 4.774l2.121-2.121a1 1 0 0 1 1.415 0l2.121 2.121a1 1 0 0 1 0 1.415l-2.12 2.12l-.001.001Z"
                      />
                    </svg>
                  </IconButton>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <Button size="small" variant="outlined" onClick={() => handleClick(index)}>
                  Patient Info
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Patient Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Patient Type</TableCell>
                  <TableCell align="left">Prescriber Name</TableCell>
                  <TableCell align="left">TGA Approval Reference Number</TableCell>
                  <TableCell align="left">Patient Initials</TableCell>
                  <TableCell align="left">Patient Date of Birth</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    {isEdit ? (
                      <TextField
                        value={patientInfo?.patientType}
                        onChange={(e) => setPatientInfo({ ...patientInfo, patientType: e.target.value })}
                        size="small"
                      />
                    ) : (
                      patientInfo?.patientType
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {isEdit ? (
                      <Stack direction={'row'}>
                        <TextField
                          placeholder={'Firstname'}
                          sx={{ mr: 1 }}
                          value={patientInfo?.prescriberFirstname}
                          onChange={(e) => setPatientInfo({ ...patientInfo, prescriberFirstname: e.target.value })}
                          size="small"
                        />
                        <TextField
                          placeholder={'Lastname'}
                          value={patientInfo?.prescriberLastname}
                          onChange={(e) => setPatientInfo({ ...patientInfo, prescriberLastname: e.target.value })}
                          size="small"
                        />
                      </Stack>
                    ) : (
                      `${patientInfo?.prescriberFirstname} ${patientInfo?.prescriberLastname}`
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {isEdit ? (
                      <TextField
                        value={patientInfo?.tgaRef}
                        onChange={(e) => setPatientInfo({ ...patientInfo, tgaRef: e.target.value })}
                        size="small"
                      />
                    ) : (
                      patientInfo?.tgaRef
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {isEdit ? (
                      <TextField
                        value={patientInfo?.patientInitial}
                        onChange={(e) => setPatientInfo({ ...patientInfo, patientInitial: e.target.value })}
                        size="small"
                      />
                    ) : (
                      patientInfo?.patientInitial
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {isEdit ? (
                      <TextField
                        type={'date'}
                        value={patientInfo?.patientDOB}
                        onChange={(e) => setPatientInfo({ ...patientInfo, patientDOB: e.target.value })}
                        size="small"
                      />
                    ) : (
                      patientInfo?.patientDOB
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {isEdit ? (
                      <ButtonGroup disableElevation variant="contained" size="small">
                        <Button color="info" onClick={handleSubmit}>
                          Submit
                        </Button>
                        <Button color="error" onClick={() => setIsEdit(false)}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <IconButton aria-label="delete" onClick={() => setIsEdit(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585l1.594-1.58zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006v-1.589z"
                          />
                          <path
                            fill="currentColor"
                            d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                          />
                        </svg>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Dialog onClose={() => setBatchEditDialog(false)} open={batchEditDialog}>
        <DialogTitle>Edit Batch Number</DialogTitle>
        <Box sx={{ p: 2 }}>
          <TextField size="small" fullWidth value={batchNumber} onChange={(e) => setBatchNumber(e.target.value)} />
          <Button onClick={updateBatchNumber} variant="contained" fullWidth sx={{ mt: 1 }}>
            Change
          </Button>
        </Box>
      </Dialog>
      <Dialog onClose={() => setExpEditDialog(false)} open={expEditDialog}>
        <DialogTitle>Edit Expiry Date</DialogTitle>
        <Box sx={{ p: 2 }}>
          <TextField
            size="small"
            fullWidth
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
          <Button onClick={updateExpiryDate} variant="contained" fullWidth sx={{ mt: 1 }}>
            Change
          </Button>
        </Box>
      </Dialog>
    </TableContainer>
  );
};

export default LineItemsTab;
