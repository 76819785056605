/* eslint-disable camelcase  */

import {
    Card,
    CircularProgress,
    Container,
    Stack,
    TableContainer,
    TablePagination,
    TextField,
    Typography
} from "@mui/material";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import Scrollbar from "../components/Scrollbar";
import Page from "../components/Page";
// import ProductsMoreMenu from "../sections/@dashboard/user/ProductsMoreMenu";
// import product_list from "../_mock/product_list";
// import stock_report from "../_mock/stock_report";
// import Axios from "../api/AxiosInstance";
import useAxiosWithAuth from "../api/useAxiosWithAuth";

const StockReport = () => {
    const [columnDefs, setColumnDefs] = useState([
        { resizable: true, field: "ProductCode", headerName: "Product Code" },
        { resizable: true, field: "AllocatedQty", headerName: "Allocated Quantity" },
        { resizable: true, field: "AvailableQty", headerName: "Available Quantity" },
        { resizable: true, field: "QtyOnHand", headerName: "Quantity at Hand" },
        { resizable: true, field: "TotalCost", headerName: "Total Cost" },
        { resizable: true, field: "Guid", headerName: "GUID" },
    ])

    const [stockReport, setStockReport] = useState([])
    const { post } = useAxiosWithAuth();

    const maxDate = new Date().toISOString().split('T')[0]

    const [reportDate, setReportDate] = useState(maxDate)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getReport = async () => {
            setIsLoading(true)
            const payload = {
                start_date: reportDate,
                end_date: reportDate
            }
            try {
                const res = await post(`product/stock-report`, payload)
                setStockReport(res.data.payload[reportDate])
                setIsLoading(false)

            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }

        getReport()

    }, [reportDate])


    return (
        <Page title="Stock Report">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
                    <Typography variant="h4" gutterBottom>
                        Stock Report
                    </Typography>
                    <TextField
                        type="date"
                        id="outlined-basic"
                        value={reportDate}
                        variant="outlined"
                        onChange={(e) => setReportDate(e.target.value)}
                        inputProps={{
                            max: maxDate
                        }}
                    />
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, maxHeight: 550, p: 3 }}>
                            <div className="ag-theme-material" style={{ height: 500, minWidth: 750 }}>
                                {isLoading ?
                                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        <CircularProgress size={72} sx={{ mb: 3 }} />
                                        <h5>Please wait while we prepare your data</h5>
                                    </div>

                                    :

                                    <AgGridReact rowData={stockReport} columnDefs={columnDefs} />
                                }
                            </div>
                        </TableContainer>
                    </Scrollbar>

                    {/* <TablePagination */}
                    {/*    rowsPerPageOptions={[10, 15, 25, 50, 100]} */}
                    {/*    component="div" */}
                    {/*    count={20} */}
                    {/*    rowsPerPage={20} */}
                    {/*    page={1} */}
                    {/*    // onPageChange={handleChangePage} */}
                    {/*    // onRowsPerPageChange={handleChangeRowsPerPage} */}
                    {/* /> */}
                </Card>
            </Container>

        </Page>
    )
}

export default StockReport;