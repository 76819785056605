import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';

const useAxiosWithAuth = () => {
    const { getIdTokenClaims } = useAuth0();
    const apiEndPoint = process.env.REACT_APP_ORDER_API_END_PONT
    //   const authorizationToken = process.env.REACT_APP_API_AUTHORIZATION_TOKEN;

    const axiosInstance = axios.create({
        baseURL: `${apiEndPoint}/api`,
        timeout: 10000,
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            try {
                const tokenClaims = await getIdTokenClaims();
                const accessToken = tokenClaims?.__raw;

                if (accessToken) {
                    config.headers.Authorization = `Token ${accessToken}`;
                }
            } catch (error) {
                console.error("Error fetching token:", error);
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxiosWithAuth;
