import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { green, teal } from '@mui/material/colors';
import { Fab } from 'react-tiny-fab';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import Register from './Register';
// import axiosInstance from '../api/AxiosInstance';
import useAxiosWithAuth from '../api/useAxiosWithAuth';

const UserList = () => {
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { get, post, delete: AxiosDelete } = useAxiosWithAuth();

  const handleClickOpen = (value) => {
    setOpen(true);
    setSelectedValue(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedValue({});
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await get('/get-user-list');
        setUserList(res?.data?.users?.reverse());
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    setOpen(false);
    setDialogueOpen(false);
    setSelectedValue({});
  }, [shouldRefresh]);

  const handleDelete = async () => {
    setShouldRefresh(true);
    try {
      const res = await AxiosDelete(`/delete-user/${selectedValue?.id}`, {
        data: {
          name: selectedValue?.name,
          user_metadata: {
            user_type: selectedValue?.type,
            supplier_code: selectedValue?.supplier_code,
          },
        },
      });
      if (res?.status === 200) {
        toast.success('User Deleted Successfully');
      }
      setShouldRefresh(false);
    } catch (err) {
      console.log(err);
      setShouldRefresh(false);
    }
  };

  const isFromModal = true;

  const handleChangeRowsPerPage = (e) => {
    setPageSize(e.target.value);
    setEndIndex(e.target.value + 1);
  };

  const handleChangePage = (e, newPage) => {
    if (newPage > pageNo) {
      setStartIndex(startIndex + pageSize);
      setEndIndex(endIndex + pageSize);
    } else {
      setStartIndex(startIndex - pageSize);
      setEndIndex(endIndex - pageSize);
    }
    setPageNo(newPage);
  };

  const handleEditModal = (value) => {
    setSelectedValue(value);
    setDialogueOpen(true);
  };

  return (
    <Page title="Users">
      <Container>
        <Typography variant="h5" sx={{ mb: 2 }}>
          User List
        </Typography>
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">User</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Supplier Code</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.slice(startIndex, endIndex).map((user, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">{user?.name}</TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">{user?.email}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={user?.type}
                      sx={{
                        bgcolor: user?.type === 'admin' ? green[200] : teal[200],
                        color: user?.type === 'admin' ? green[800] : teal[800],
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">
                      {user?.supplier_code === '' ? 'N/A' : user?.supplier_code}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent="center">
                      <Button
                        variant="outlined"
                        size="small"
                        color="warning"
                        sx={{
                          mr: 2,
                        }}
                        onClick={() => handleEditModal(user)}
                      >
                        Update
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{
                          mr: 2,
                        }}
                        onClick={() => handleClickOpen(user)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={userList?.length}
            rowsPerPageOptions={[5, 10, 25]}
            page={pageNo}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Container>
      <Fab
        onClick={() => setDialogueOpen(true)}
        alwaysShowTitle
        mainButtonStyles={{ background: 'red' }}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 12 12"
          >
            <path
              fill="currentColor"
              d="M6.5 1.75a.75.75 0 0 0-1.5 0V5H1.75a.75.75 0 0 0 0 1.5H5v3.25a.75.75 0 0 0 1.5 0V6.5h3.25a.75.75 0 0 0 0-1.5H6.5V1.75Z"
            />
          </svg>
        }
        text={'Add User'}
      />
      <Dialog
        style={{ overflowY: 'visible' }}
        onClose={() => {
          setDialogueOpen(false);
          setSelectedValue({});
        }}
        open={dialogueOpen}
        fullWidth
        maxWidth={'lg'}
        sx={{ p: 3 }}
      >
        <Box sx={{ p: 3 }}>
          <Register user={selectedValue} setShouldRefresh={setShouldRefresh} isFromModal={isFromModal} />
        </Box>
      </Dialog>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Are you sure you want to delete this {selectedValue?.name} ?</DialogTitle>
        <Divider />
        <Stack direction="row" justifyContent={'end'} sx={{ my: 2 }}>
          <Button color="error" variant="outlined" onClick={handleDelete}>
            Yes
          </Button>
          <Button color="success" variant="outlined" sx={{ mx: 2 }} onClick={handleClose}>
            No
          </Button>
        </Stack>
      </Dialog>
    </Page>
  );
};

export default UserList;
