import {Box, CircularProgress} from "@mui/material";

const LoadingScreen = () => {
    return(
        <div style={{height: "100vh", background:"rgba(255,255,255,1)", display:"flex", justifyContent:'center', alignItems:"center"}}>
            <CircularProgress size={100} color={"primary"}/>
        </div>
    )
}

export default LoadingScreen;