/* eslint-disable camelcase  */

import { AgGridReact } from "ag-grid-react";
import {
    Card, CircularProgress,
    Container,
    Stack,
    TableContainer, TablePagination,
    Typography
} from "@mui/material";

import { useEffect, useState } from "react";

import { Action, Fab } from "react-tiny-fab";
import Page from "../components/Page";

import Scrollbar from "../components/Scrollbar";
import useAxiosWithAuth from "../api/useAxiosWithAuth";

const SupplierList = () => {
    const [columnDefs, setColumnDefs] = useState([
        { resizable: true, field: "SupplierCode", headerName: "Supplier Code" },
        { resizable: true, field: "SupplierName", headerName: "Supplier Name", width: 300 },
        { resizable: true, field: "Email", headerName: "Supplier Email", width: 300 },
        { resizable: true, field: "PhoneNumber", headerName: "Supplier Mobile" },
        { resizable: true, field: "CreatedOn", headerName: "Created On", width: 300 },
        { resizable: true, field: "CreatedBy", headerName: "Created By", width: 300 },
        { resizable: true, field: "Website", headerName: "Supplier Website" },
    ])

    const [isLoading, setIsLoading] = useState(true)
    const [supplierList, setSupplierList] = useState([])
    const [paginationMeta, setPaginationMeta] = useState({})
    const [asyncRunning, setAsyncRunning] = useState(true)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const { get, post } = useAxiosWithAuth();

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const response = await post(`/unleashed/suppliers?page_size=${rowsPerPage}&page_number=${page + 1}`, {})
                const sm = response?.data?.payload[0]?.CreatedOn.slice(6, 19)
                if (response.status === 200) {
                    setSupplierList(response?.data?.payload.map(item => (
                        {
                            SupplierCode: item?.SupplierCode === "" ? "N/A" : item?.SupplierCode,
                            SupplierName: item?.SupplierName === "" ? "N/A" : item?.SupplierName,
                            Email: item?.Email === "" ? "N/A" : item?.Email,
                            PhoneNumber: `${item?.phone_number === "" ? "N/A" : item?.phone_number} / ${item?.MobileNumber === "" ? "N/A" : item?.MobileNumber}`,
                            CreatedOn: `${new Date(parseInt(item?.CreatedOn.slice(6, 19), 10)).toDateString()} at ${new Date(parseInt(item?.CreatedOn.slice(6, 19), 10)).toLocaleTimeString()}`,
                            CreatedBy: item?.CreatedBy,
                            Website: item?.Website === "" ? "N/A" : item?.Website

                        }
                    )))
                    setPaginationMeta(response?.data?.meta)
                    setTotal(response?.data?.meta?.total)
                    setIsLoading(false)
                    setAsyncRunning(false)
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
                setAsyncRunning(false)
            }
        }

        getData()
    }, [rowsPerPage, page, refresh])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRefresh = async () => {
        try {
            setIsLoading(true)
            setRefresh(true)
            const response = await get(`/unleashed/sync-suppliers`)
            if (response?.data?.error === null) {
                setRefresh(false)
                setIsLoading(false)
            }
        } catch (err) {
            console.log(err)
            setRefresh(false)
            setIsLoading(false)
        }

    }

    return (
        <Page title="Supplier List">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
                    <Typography variant="h4" gutterBottom>
                        Supplier List
                    </Typography>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, maxHeight: 550, p: 3 }}>
                            <div className="ag-theme-material" style={{ height: 500, minWidth: 750 }}>
                                {isLoading ?
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}>
                                        <CircularProgress size={72} sx={{ mb: 3 }} />
                                        <h5>Please wait while we prepare your data</h5>
                                    </div>
                                    :
                                    <AgGridReact enableCellTextSelection="true" rowData={supplierList}
                                        columnDefs={columnDefs} />
                                }
                            </div>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <Fab
                onClick={handleRefresh}
                alwaysShowTitle
                mainButtonStyles={{ background: "#00c853" }}
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1536">
                        <path fill="currentColor"
                            d="M1511 928q0 5-1 7q-64 268-268 434.5T764 1536q-146 0-282.5-55T238 1324l-129 129q-19 19-45 19t-45-19t-19-45V960q0-26 19-45t45-19h448q26 0 45 19t19 45t-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117q8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45t19-45l138-138Q969 256 768 256q-134 0-250 65T332 500q-11 17-53 117q-8 23-30 23H50q-13 0-22.5-9.5T18 608v-7q65-268 270-434.5T768 0q146 0 284 55.5T1297 212l130-129q19-19 45-19t45 19t19 45z" />
                    </svg>
                }
                text={"Refresh"}
            />

        </Page>
    )
}

export default SupplierList;