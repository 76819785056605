import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { Fab, Action } from 'react-tiny-fab';
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { toast } from "react-toastify";
import PrimaryDetailsTab from "../components/PrimaryDetailsTab";
import BillingShippingDetailsTab from "../components/BillingShippingDetailsTab";
import OthersTab from "../components/OthersTab";
import LineItemsTab from "../components/LineItemsTab";
import CustomerDetailsTab from "../components/CustomerDetailsTab";
import useAxiosWithAuth from "../api/useAxiosWithAuth";

/* eslint-disable camelcase */

const OrderDetails = () => {
    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState("1")
    const [primaryDetails, setPrimaryDetails] = useState({})
    const [shippingDetails, setShippingDetails] = useState({})
    const [billingDetails, setBillingDetails] = useState({})
    const [othersDetails, setOthersDetails] = useState({})
    const [lineItems, setLineItems] = useState({})
    const { id } = useParams()
    const { user } = useAuth0()
    const { get, post } = useAxiosWithAuth();

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const response = await get(`/order/${id}`)
                const {
                    billing,
                    shipping,
                    meta_data,
                    line_items,
                    tax_lines,
                    shipping_lines,
                    _links,
                    ...rest
                } = response?.data?.payload
                setPrimaryDetails(rest);
                setBillingDetails(billing);
                setShippingDetails(shipping);
                setLineItems(line_items)
                setOthersDetails({ meta_data, tax_lines, shipping_lines, _links })
                if (response.status === 200) {
                    setProduct(response?.data?.payload)
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
        }

        getData()
    }, [id])

    const postToWS8 = async (id) => {
        try {
            const res = await post(`/post-to-wares8/${id}`)
            if (res?.status === 200) {
                toast.success(res?.data?.payload)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                            <Tab label="Primary Details" value="1" />
                            <Tab label="Shipping and Billing Details" value="2" />
                            <Tab label="Line Items" value="4" />
                            <Tab label="Customer Details" value="5" />
                            <Tab label="Others" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <PrimaryDetailsTab primaryDetails={primaryDetails} />
                    </TabPanel>
                    <TabPanel value="2">
                        <BillingShippingDetailsTab billingDetails={billingDetails} shippingDetails={shippingDetails} />
                    </TabPanel>
                    <TabPanel value="3"><OthersTab othersDetails={othersDetails} /></TabPanel>
                    <TabPanel value="4"><LineItemsTab lineItemsDetails={lineItems} id={primaryDetails?._id} /></TabPanel>
                    <TabPanel value="5"><CustomerDetailsTab customerId={primaryDetails?.customer_id} /></TabPanel>
                </TabContext>
            </Box>
            {user["http://healthhouse.com/user_type"] === "admin" ?
                <Fab
                    alwaysShowTitle
                    mainButtonStyles={{ background: "#E53935" }}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                        <path fill="currentColor" fillRule="evenodd"
                            d="M6.672 1.911a1 1 0 1 0-1.932.518l.259.966a1 1 0 0 0 1.932-.518l-.26-.966ZM2.429 4.74a1 1 0 1 0-.517 1.932l.966.259a1 1 0 0 0 .517-1.932l-.966-.26Zm8.814-.569a1 1 0 0 0-1.415-1.414l-.707.707a1 1 0 1 0 1.415 1.415l.707-.708Zm-7.071 7.072l.707-.707A1 1 0 0 0 3.465 9.12l-.708.707a1 1 0 0 0 1.415 1.415Zm3.2-5.171a1 1 0 0 0-1.3 1.3l4 10a1 1 0 0 0 1.823.075l1.38-2.759l3.018 3.02a1 1 0 0 0 1.414-1.415l-3.019-3.02l2.76-1.379a1 1 0 0 0-.076-1.822l-10-4Z"
                            clipRule="evenodd" />
                    </svg>}
                    text={"Actions"}
                >
                    <Action
                        style={{ background: "#01579B" }}
                        text="Post to Ware S8"
                        onClick={() => postToWS8(primaryDetails?._id)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                            <g fill="currentColor">
                                <path
                                    d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26l.001.002l4.995 3.178l1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215l7.494-7.494l1.178-.471l-.47 1.178Z" />
                                <path
                                    d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                            </g>
                        </svg>
                    </Action>
                </Fab>
                :
                ""
            }
        </>
    )
}

export default OrderDetails;